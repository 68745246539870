import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { notification } from 'antd';

import { Table, Modal, Select, Input, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UsergroupAddOutlined,
    FilterFilled,
    NodeIndexOutlined,
    PrinterOutlined,
    FileZipOutlined,
    CarOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import PrintProcessing from '@controls/print-processing';

import { exception, securityRestriction } from '@extensions/notification';
import { getEnumList, delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import CertificatePdf from '@print-forms/handover-takeover-certificate/certificate-pdf';

import { IShipmentJournal } from '@entities/shipment-journal';
import { IUserSession } from '@entities/user-session';
import { IJournalFilter } from '@entities/journal-filter';

import { JournalStatus, enumLabel as journalStatusLabel } from '@enums/journal-status';
import { AreaType } from '@enums/area-type';
import { enumLabel as jobStatusLabel } from '@enums/job-status';
import { Permission, hasPermission } from '@enums/permission';
import { IEnumItem } from '@enums/enum-item';
import { DeliveryOption, enumLabel as deliveryOptionLabel } from '@enums/delivery-option';

import { TruckIcon, AirplaneIcon, PickupIcon } from '@icons/index';

import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'ShipmentJournals';

const ShipmentJournals = () => {
    const initFilter: IJournalFilter = {
        statuses: [JournalStatus.New, JournalStatus.InProcess],
        includeCity: true,
        includeQty: true,
        isArchived: false,
    };

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const filter = useAppSelector<IJournalFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<IShipmentJournal>>([]);
    const [currentJournal, setCurrentItem] = useState<IShipmentJournal>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [statuses] = useState<Array<IEnumItem>>(getEnumList(JournalStatus, journalStatusLabel));
    const [deliveryOptions] = useState<Array<IEnumItem>>(getEnumList(DeliveryOption, deliveryOptionLabel));

    const [printData, setPrintData] = useState();
    const [startPrint, setStartPrint] = useState(false);
    const [loadingPrintData, setLoadingPrintData] = useState(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('shipmentjournals', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения документов отгрузки', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setEntities(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf(<CertificatePdf printData={printData} />);
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = () => {
        if (!currentJournal) return;

        setLoadingPrintData(true);

        serverFetch(`shipmentjournals/${currentJournal.id}/print`, { method: 'GET' })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка печати акта приема-передачи груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const setArchive = () => {
        if (!currentJournal) return;

        serverFetch(`shipmentjournals/${currentJournal.id}/archive`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка архивирования журнала', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = () => {
        if (!currentJournal) return;

        serverFetch(`shipmentjournals/${currentJournal.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления документа', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Детали',
                        key: 'details',
                        disabled: loading || !currentJournal,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentJournal?.id}`);
                        },
                    },
                    {
                        label: 'Управление машиной',
                        key: 'add',
                        type: 'primary',
                        disabled: !currentJournal || currentJournal.deliveryOption !== DeliveryOption.Truck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            if (currentJournal?.truckId) {
                                navigate(`${resolved.pathname}/${currentJournal?.id}/truck/${currentJournal.truckId}`);
                            } else {
                                navigate(`${resolved.pathname}/${currentJournal?.id}/truck/new`);
                            }
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: loading || !currentJournal || !!currentJournal.truckId,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteShipmentJournal)) {
                                securityRestriction(api, [Permission.DeleteShipmentJournal]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить документ "${currentJournal?.journalNumber}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Назначить исполнителя',
                        key: 'assignJob',
                        disabled: !currentJournal || !currentJournal.truckId,
                        icon: <UsergroupAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/performers/${AreaType.Shipment}/${currentJournal?.truckId}`);
                        },
                    },
                    {
                        label: 'Маршрутный лист',
                        key: 'routesheet',
                        disabled: loading || !currentJournal,
                        icon: <NodeIndexOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            navigate(`/warehouse/routesheet/${currentJournal?.id}`);
                        },
                    },
                    {
                        label: 'Печать акт приема - передачи',
                        key: 'prinCertificate',
                        disabled: loading || !currentJournal || startPrint || currentJournal.status !== JournalStatus.Completed,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            if (currentJournal?.deliveryOption !== DeliveryOption.Truck || currentJournal?.truckId) {
                                getPrintData();
                                return;
                            }

                            modal.confirm({
                                title: `Для документа "${currentJournal?.journalNumber}" не назначена машина, продолжить?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    getPrintData();
                                },
                            });
                        },
                    },
                    {
                        label: 'В архив',
                        key: 'archive',
                        disabled: loading || !currentJournal || currentJournal.status !== JournalStatus.Completed,
                        icon: <FileZipOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            modal.confirm({
                                title: `Отправить документ "${currentJournal?.journalNumber}" в архив?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    setArchive();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='journalNumber'
                        placeholder='Журнал'
                        value={filter?.journalNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, journalNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='deliveryOption'
                        placeholder='Вариант доставки'
                        value={filter?.deliveryOption}
                        size='middle'
                        allowClear
                        style={{ width: 170 }}
                        onChange={(value) => d(setFilter({ ...filter, deliveryOption: value }, filterContext))}
                        options={deliveryOptions.map((t) => {
                            return { value: t.value, label: t.label };
                        })}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Машина'
                        value={filter?.truckNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, truckNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус документа'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 220 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((t) => {
                            return { value: t.value, label: t.label };
                        })}
                    ></Select>,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            let entity = entities.find((o) => o.id === selectedRowKeys[0]);
            setCurrentItem(entity);
        } else {
            setCurrentItem(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IShipmentJournal> = [
            {
                title: 'Документ',
                dataIndex: 'journalNumber',
                width: 100,
            },
            {
                title: 'Статус документа',
                width: 140,
                render: (_, record) => {
                    return <>{journalStatusLabel(record.status)} </>;
                },
            },
            {
                width: 50,
                align: 'center',
                render: (_, record) => {
                    switch (record.deliveryOption) {
                        case DeliveryOption.Truck:
                            return (
                                <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Truck)}>
                                    <TruckIcon className='delivery-option' />
                                </Tooltip>
                            );
                        case DeliveryOption.Pickup:
                            return (
                                <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Pickup)}>
                                    <PickupIcon className='delivery-option' />
                                </Tooltip>
                            );
                        case DeliveryOption.Airplane:
                            return (
                                <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Airplane)}>
                                    <AirplaneIcon className='delivery-option' />
                                </Tooltip>
                            );
                        default:
                            return '';
                    }
                },
            },
            {
                title: 'Машина',
                dataIndex: 'truckNumber',
                width: 120,
            },
            {
                title: 'Дата отправки',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Исполнитель',
                key: 'performerName',
                dataIndex: 'performerName',
                width: 200,
            },
            {
                title: 'Статус выполнения',
                key: 'jobStatus',
                width: 160,
                render: (_, record) => {
                    return <>{jobStatusLabel(record.jobStatus)} </>;
                },
            },
            {
                title: 'Города',
                dataIndex: 'cities',
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ color: record.totalScannedBoxQty === record.totalBoxQty ? '#228B22' : 'red' }}>
                                {record.totalScannedBoxQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalBoxQty}</span>
                        </>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={entities}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 237 : 179}px)` }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record: IShipmentJournal) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
            />
        );
    };

    return (
        <>
            {modalContextHolder}
            {renderToolbar()}
            { renderFilter()}
            {renderTable()}

            {(loadingPrintData || startPrint) && <PrintProcessing />}

            {contextHolder}
        </>
    );
};

export default ShipmentJournals;
