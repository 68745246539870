import React, { useState, useEffect,useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { Modal, Table, Row, Col, Divider, Select, Input, Form, Switch, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    CheckOutlined,
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    FilterFilled,
    UnorderedListOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { getEnumList, delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@src/core/entities/user-permission';
import { IUserFilter } from '@entities/user-filter';
import { IWarehouse } from '@entities/warehouse';

import { UserType, enumLabel as userTypeLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { IEnumItem } from '@enums/enum-item';

import { MobileIcon, MobileNoneIcon } from '@icons/index';

const filterContext: string = 'Users';

const Users = () => {
    const initFilter: IUserFilter = { isActive: true, isArchived: false };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const [api, contextHolder] = notification.useNotification();

    const [users, setUsers] = useState<Array<IUser>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentUser, setCurrentUser] = useState<IUser>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [userTypes] = useState(getEnumList(UserType, userTypeLabel));
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);

    const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoadingUsers(true);

            let promises = [
                await serverFetch('users', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setUsers(result[0][0]);
                setWarehouses(result[0][1]);

                setLoadingUsers(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (currentUser) {
            loadPermissions(currentUser.id);
        } else {
            setUserPermissions([]);
        }
    }, [currentUser]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const loadPermissions = (id: string | undefined) => {
        if (!id) return;

        setLoadingPermissions(true);

        serverFetch(`users/${id}/permissions`, { method: 'GET' })
            .then((data) => {
                let entities: Array<IUserPermission> = [];
                permissions.map((p: IEnumItem) => {
                    let permission = data.find((e: IUserPermission) => e.permissionCode === p.value);
                    if (permission) {
                        entities.push({ userId: id, name: permissionLabel(p.value), permissionCode: p.value, isActive: true });
                    } else {
                        entities.push({ userId: id, name: permissionLabel(p.value), permissionCode: p.value, isActive: false });
                    }
                });

                setUserPermissions(entities);
                setLoadingPermissions(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения прав', ex, () => d(userLoaded(undefined)));
                setLoadingPermissions(false);
            });
    };

    const onDelete = () => {
        if (!currentUser) return;

        serverFetch(`users/${currentUser.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loadingUsers,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageUsers)) {
                                securityRestriction(api, [Permission.ManageUsers]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageUsers) ? 'Изменить' : 'Информация о пользователе'
                        }`,
                        key: 'edit',
                        disabled: !currentUser,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentUser?.id}`);
                        },
                    },
                    {
                        label: 'Заказы пользователя',
                        key: 'orders',
                        disabled: !currentUser || currentUser.type === UserType.System,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`/orders/${currentUser?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentUser,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteUser)) {
                                securityRestriction(api, [Permission.DeleteUser]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить пользователя "${currentUser?.fullName}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = users.find((o) => o.id === selectedRowKeys[0]);
            setCurrentUser(entity);
        } else {
            setCurrentUser(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 250 }}
                            key='searchText'
                            placeholder='Логин / ФИО / Email'
                            value={filter?.searchText}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            key='userType'
                            placeholder='Тип'
                            value={filter?.types}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value: any) => d(setFilter({ ...filter, types: value }, filterContext))}
                            options={userTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,

                        <Select
                            key='warehouseCode'
                            placeholder='Склад'
                            value={filter?.warehouseCode}
                            size='middle'
                            allowClear
                            style={{ width: 220 }}
                            onChange={(value) => d(setFilter({ ...filter, warehouseCode: value }, filterContext))}
                            options={warehouses.map((t) => {
                                return { value: t.code, label: t.code };
                            })}
                        />,

                        <Form.Item key='isActive' label='Активные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isActive}
                                onChange={(value: any) => {
                                    d(setFilter({ ...filter, isActive: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderUsersTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 200,
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Тип',
                width: 100,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return userTypeLabel(record.type);
                },
            },
            {
                title: 'Склад',
                dataIndex: 'warehouseCode',
                width: 80,
                render: (_, record) => {
                    return (
                        <>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </>
                    );
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    if (record.type === UserType.System) return '';

                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        const tableLoading = {
            spinning: loadingUsers,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={users}
                loading={{
                    spinning: loadingUsers,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height, x: 'auto' }}
            />
        );
    };

    const renderPermissionsTable = () => {
        const columns: ColumnsType<IUserPermission> = [
            {
                title: '',
                width: 40,
                onCell: (record: IUserPermission, _) => ({
                    colSpan: record.permissionCode < 0 ? 2 : 1,
                }),
                render: (_, record) => {
                    if (record.permissionCode < 0) {
                        return <span className='permissionGroup'>{permissionLabel(record.permissionCode)}</span>;
                    }

                    return <div style={{ textAlign: 'center' }}> {record.isActive ? <CheckOutlined /> : ''}</div>;
                },
            },
            {
                title: '',
                dataIndex: 'name',
                onCell: (record: IUserPermission, _) => ({
                    colSpan: record.permissionCode < 0 ? 0 : 1,
                }),
            },
        ];

        return (
            <Table
                rowKey='permissionCode'
                showHeader={false}
                size='small'
                columns={columns}
                dataSource={userPermissions}
                loading={{
                    spinning: loadingPermissions,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 150px)` }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 190px - ${filterHeight}px)` }}>
            <Row>
                <Col span={14}>
                    {renderToolbar()}
                    {renderFilter()}
                    {renderUsersTable()}
                </Col>
                {currentUser?.type === UserType.System && (
                    <Col offset={1} span={9}>
                        <Divider orientation='left'>Разрешения</Divider>
                        {renderPermissionsTable()}
                    </Col>
                )}

                {contextHolder}
            </Row>
        </div>
    );
};

export default Users;
